@charset "utf-8";
/* CSS Document */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');

html, body {
    overflow-x: hidden;
    height:     100%;
    background: #000;
}

body {
    background:  #000;
    padding:     0;
    margin:      0;
    font-family: 'Open Sans', sans-serif;
    color:       #FFF;
    font-size:   20px;
    line-height: 1.5;
}

h1, h2, h3, h4 {
    padding: 0;
    margin:  0;
}

h1 {
    font-size:       40px;
    text-decoration: none;
    font-weight:     normal;
}

h2 {
    font-size:       30px;
    text-decoration: none;
    font-weight:     normal;
}

h3 {
    font-size:       20px;
    text-decoration: none;
    font-weight:     bold;
}

.black svg path {
    fill: #FFF;
}


a {
    color:           #EBFF64;
    text-decoration: none;
}

a:hover {
    color: #FBFFE1;
}

a:hover path {
    fill: #EBFF64;
}

.padded {
    padding: 0 20px;
}

.wrap {
    flex-direction: column;
    max-width:      1300px;
    margin:         auto;
    display:        flex;
}

.logo {
    width:  100%;
    height: auto;
}

.color_main {
    background-color: #009473;
    color:            #FFF;
    font-size:        24px;
}

.color_gallery {
    background-color: #12738E;
    color:            #FFF;
    font-size:        24px;
}

.color_rules {
    background-color: #87128E;
    color:            #FFF;
    font-size:        18px;
}

.color_main small {
    font-size:  18px;
    display:    block;
    margin-top: 20px;
}

.color_main small.note {
    font-size: 14px;
}

.wrap.row {
    flex-direction: row;
}

.wrap.row article {
    white-space: normal;
    flex:        1 1 0
}

.wrap article {
    padding: 20px;
}

.map {
    margin-top: 20px;
}

article.gallery {
    display:        flex;
    flex-direction: row;
    flex-wrap:      wrap;
}

article.gallery span {
    height: 120px;
    width:  120px;
    margin: 5px;
    border: 1px #000 solid;
    cursor: pointer;
}

article.gallery span img {
    height: 100%;
    width:  100%;
}

.info {
    background: #7bd0e8;
    border-radius: 5px;
    margin: 20px;
    padding: 20px;
    color: #000;
    flex: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    cursor: pointer;
}

.modal img {
    height: 90%;
    object-fit: contain;
}

/************************************************************************************
Reactive
*************************************************************************************/
@media screen and (max-width: 1350px) {
    /*.logo img { width:100%;}*/
}

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 1000px) {
    .wrap.row {
        flex-direction: column;
    }

    .wrap.row article + article {
        padding-top: 0;
    }
}

@media screen and (min-width: 600px) {

}


@media screen and (max-width: 600px) {

}

@media screen and (max-width: 400px) {

}


/************************************************************************************
CLEARFIX
*************************************************************************************/
.clearfix {
    margin-bottom: 10px;
}

/* float clearing for IE6 */
* html .clearfix {
    height:   1%;
    overflow: visible;
}

/* float clearing for IE7 */
* + html .clearfix {
    min-height: 1%;
}

/* float clearing for everyone else */
.clearfix:after {
    clear:      both;
    content:    ".";
    display:    block;
    height:     0;
    visibility: hidden;
    font-size:  0;
    width:      100%
}

.footer {
    font-size:  10px;
    text-align: center;
}
